<template>
  <div ref="chartWrap" class="chart-wrap">
    <div ref="chart"></div>
    <div class="chart-total font-weight-semi-bold text-uppercase" :style="{'font-size': fontSizeComputed }">{{ chartData.total }}</div>
  </div>
</template>

<script>
import Apexcharts from "@/utilities/charts/apexcharts";

function dependSize(text, width, ration, maxSize = 42) {
  let length = text.replace(/^\s+|\s+$|\(|\)|8-/gm, '').length,
      size = width / length * parseFloat(ration);
  return (size > maxSize ? maxSize : size) + 'px';
}

export default {
  name: 'DonutChart',
  props: {
    //  {
    //   total: '$82k',
    //   labels: ['Brellient Earth', 'Tiffany & Co', 'Cartier'],
    //   series: [55, 25, 21],
    // },
    chartData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    chart: null,
    chartOptionsDefault: {
      chart: {
        height: 250,
        type: 'donut',
      }
    },
  }),
  computed: {
    fontSizeComputed: function () {
       return dependSize(this.chartData.total, this.chartOptionsDefault?.chart?.height || 250, 1, 42);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = new Apexcharts(this.$refs.chart, {...this.chartOptionsDefault, ...this.chartData});
    });
  },
  destroyed() {
    this.chart.destroy();
  }
}
</script>

<style lang="scss" scoped>
.chart-wrap {
  position: relative;
}

.chart-total {
  position: absolute;
  top: 50%;
  left: 50%;
  //font-size: 40px;
  transform: translate(-50%, -50%);
}
</style>
